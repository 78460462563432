import { createComponent } from '@lit-labs/react';
import {
  DsbIcon,
  IconProps,
} from '@wisereducacao-npm/design-system-brands-web/dist/components/icon';
import * as React from 'react';

export const Icon = createComponent({
  tagName: 'dsb-icon',
  elementClass: DsbIcon,
  react: React,
  events: {},
}) as unknown as React.FC<IconProps>;

/* c8 ignore start */
import styled, { css } from 'styled-components';

import { breakpoints, convertHexToRGBA } from 'src/utils';

import { Typography } from '../DS/Typography';

interface ProfileContentWrapperProps {
  isAlreadyOnProfile?: boolean;
}

interface ProfileImageWrapperProps {
  noHover?: boolean;
}

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: ${theme.spacing.xxxs} 0;
    background-color: ${convertHexToRGBA(
      theme.color.primary.medium,
      theme.opacity.medium,
    )};
    cursor: default;

    a {
      cursor: default;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
    padding: 0 ${theme.spacing.xxxs} 0;
    max-width: ${theme.width.maxWidth};

    @media ${breakpoints.md} {
      padding: 0 ${theme.spacing.insetMd} 0;
    }
  `}
`;

export const WelcomeBox = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.quarck};
  `}
`;

export const UserFirstName = styled(Typography)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.quarck};
  `}
`;

export const ProfileImageWrapper = styled.div<ProfileImageWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.primary.dark};
  height: 35px;
  width: 35px;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.neutral.pure};
  margin-right: 4px;
  cursor: default;

  ${({ noHover }) =>
    !noHover &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.color.primary.medium};
      }

      &:active {
        border: ${({ theme }) => `1px solid ${theme.color.neutral.highContrast}`};
        background-color: ${({ theme }) => theme.color.primary.medium};
      }
    `}
`;

export const SkeletonWrapper = styled.div`
  width: 35px;
`;

export const ProfileContentWrapper = styled.div<ProfileContentWrapperProps>`
  ${({ isAlreadyOnProfile }) => css`
    display: flex;
    align-items: center;

    ${!isAlreadyOnProfile &&
    css`
      &:active {
        ${ProfileImageWrapper} {
          border: ${({ theme }) => `1px solid ${theme.color.neutral.highContrast}`};
          background-color: ${({ theme }) => theme.color.primary.medium};
        }
      }

      &:hover {
        cursor: pointer;
        ${ProfileImageWrapper} {
          background-color: ${({ theme }) => theme.color.primary.medium};
        }
      }
    `}
  `}
`;
/* c8 ignore stop */

import { IPerformance, AcceptableTags } from './types';

export class PerformanceWatcher implements IPerformance {
  constructor(private readonly elasticPerformanceAdapter: IPerformance) {}

  instrumentComponent(
    component: React.ComponentType<unknown>,
    transactionName: string,
    transactionType: string,
  ): React.ComponentType<unknown> {
    return this.elasticPerformanceAdapter.instrumentComponent(
      component,
      transactionName,
      transactionType,
    );
  }

  withMonitoring(
    action: (...args: unknown[]) => unknown,
    eventName: string,
    eventType?: string | 'custom',
    labels?: Record<string, AcceptableTags>,
  ): void {
    return this.elasticPerformanceAdapter.withMonitoring(
      action,
      eventName,
      eventType,
      labels,
    );
  }
}

/* c8 ignore start */
import styled, {
  css,
  DefaultTheme,
  FlattenSimpleInterpolation,
} from 'styled-components';

import { ToastType } from './types';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .toast-container {
      width: 100%;
      max-width: 485px;
      padding: ${theme.spacing.insetXs};
      border-radius: ${theme.border.radius.sm};
      top: ${theme.spacing.xxl};

      .Toastify {
        &__toast {
          padding: 0;
          margin-bottom: ${theme.spacing.insetXs};
          border-radius: ${theme.border.radius.sm};
        }

        &__toast-body {
          padding: 0;
        }

        &__close-button {
          display: none;
        }

        &__toast-icon {
          display: none;
        }
      }
    }
  `}
`;

type ContentProps = {
  type?: ToastType;
};

export const Content = styled.div<ContentProps>`
  ${({ theme, type = 'warning' }) => css`
    display: flex;
    padding: ${theme.spacing.insetSm};
    border-radius: ${theme.border.radius.sm};

    ${modifiers[type](theme)}
  `}
`;

export const TextGroup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 ${theme.spacing.insetXxs};
  `}
`;

const modifiers: {
  [key: string]: (theme: DefaultTheme) => FlattenSimpleInterpolation;
} = {
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.color.primary.pure};
    border: ${theme.border.width.hairline} solid ${theme.color.feedback.success.pure};
  `,
  error: (theme: DefaultTheme) => css`
    background-color: ${theme.color.primary.pure};
    border: ${theme.border.width.hairline} solid ${theme.color.feedback.error.pure};
  `,
  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.color.primary.pure};
    border: ${theme.border.width.hairline} solid ${theme.color.feedback.alert.pure};
  `,
};
/* c8 ignore stop */

import { CookiesKeys } from 'src/modules/Auth/constants';
import { api as emApi } from 'src/services/config/em-api';
import { setCookie, clearCookie, getCookie } from 'src/utils/cookies';

export function saveUserAuthToken(authToken: string) {
  emApi.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  setCookie(CookiesKeys.AUTH_TOKEN, authToken, {
    path: '/',
  });
}

export function deleteUserAuthToken() {
  delete emApi.defaults.headers.common['Authorization'];
  clearCookie(CookiesKeys.AUTH_TOKEN, {
    path: '/',
  });
}

export function doesUserHaveAnAuthToken() {
  return (
    !!emApi.defaults.headers.common['Authorization'] ||
    !!getCookie(CookiesKeys.AUTH_TOKEN)
  );
}

export function getUserAuthToken() {
  return getCookie(CookiesKeys.AUTH_TOKEN);
}

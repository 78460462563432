import { createComponent } from '@lit-labs/react';
import {
  DsbButton,
  ButtonProps,
} from '@wisereducacao-npm/design-system-brands-web/dist/components/button';
import * as React from 'react';

interface DsButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: (event: React.BaseSyntheticEvent) => void;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined;
}

export const Button = createComponent({
  tagName: 'dsb-button',
  elementClass: DsbButton,
  react: React,
  events: {
    onClick: 'click',
  },
}) as unknown as React.FC<DsButtonProps>;

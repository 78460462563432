/* c8 ignore start */
import styled, { css } from 'styled-components';

import { Typography } from 'src/components';
import { breakpoints, convertHexToRGBA } from 'src/utils';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: ${theme.spacing.sm} ${theme.spacing.xxxs};
    max-width: ${theme.width.maxWidth};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    max-width: 434px;
    margin-top: ${theme.spacing.sm};

    img {
      width: 100%;
      height: auto;
    }

    @media ${breakpoints.md} {
      flex-direction: row;
      flex-direction: row-reverse;
      max-width: 100%;

      ${ImageBox} {
        width: 100%;
        max-width: 600px;
        flex: 1;
      }

      ${TextBox} {
        margin-right: 0;
        margin-top: 0;
        max-width: 410px;
      }
    }

    @media ${breakpoints.lg} {
      ${TextBox} {
        margin-right: 100px;
      }
    }
  `}
`;

export const ImageBox = styled.picture`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    background-color: ${convertHexToRGBA(
      theme.color.primary.light,
      theme.opacity.semitransparent
    )};
    border-radius: ${theme.border.radius.lg};
    padding: ${theme.spacing.lg};
  `}
`;

export const TextBox = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex: none;
    flex-direction: column;
    text-align: left;
    margin-top: ${theme.spacing.sm};
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    margin: ${theme.spacing.xs} 0 ${theme.spacing.xxs};
  `}
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => css`
    a {
      color: ${theme.color.secondary.pure};
    }
  `}
`;
/* c8 ignore stop */

import error500 from 'src/assets/error-500.svg';
import { Logo, Typography } from 'src/components';
import { useOnceCall } from 'src/hooks';
import * as dataLayer from 'src/utils/dataLayer';

import * as S from './styles';

interface GenericErrorProps {
  errorMessage: string;
}

const GenericError = ({ errorMessage }: GenericErrorProps) => {
  useOnceCall(() => {
    dataLayer.push({
      event: dataLayer.event.VIRTUAL_PAGE_VIEW,
      page_url: window.location.pathname,
      error_message: errorMessage,
    });
  });

  return (
    <S.Wrapper>
      <Logo />
      <S.Content>
        <S.ImageBox>
          <img src={error500} alt='error 500' />
        </S.ImageBox>
        <S.TextBox>
          <Typography component='heading' size='x-large' color='neutral-medium'>
            Algo deu errado
          </Typography>
          <S.Title component='paragraph' size='x-large'>
            Tente acessar novamente mais tarde
          </S.Title>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  );
};

export { GenericError };

import styled from 'styled-components';

import { breakpoints } from 'src/utils';

interface ModalProps {
  isOpen: boolean;
}

export const OverFlowWrapper = styled.div<ModalProps>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  display: flex;
  z-index: ${({ isOpen }) => (isOpen ? 9999 : -1)};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
`;

export const ModalContainer = styled.div<ModalProps>`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: all 0.2s ease-in-out;
  width: 425px;
  min-height: 322px;
  background-color: ${({ theme }) => theme.color.neutral.lowContrast};
  border-radius: ${({ theme }) =>
    `${theme.border.radius.xs} ${theme.border.radius.xs} 0 0`};

  @media ${breakpoints.lg} {
    min-height: unset;
    width: 523px;
    height: 249px;
  }
`;

export const ModalHeader = styled.div`
  padding: 8px 8px 0 0;
  color: ${({ theme }) => theme.color.neutral.pure};
  display: flex;
  justify-content: flex-end;
  height: ${({ theme }) => theme.spacing.lg};
`;

export const ModalBody = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.xxs}`};
`;

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xxxs};
`;

export const ModalFooter = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.xxs} ${theme.spacing.sm}`};
  margin-top: ${({ theme }) => theme.spacing.sm};

  display: flex;
  flex-direction: column;

  @media ${breakpoints.lg} {
    flex-direction: row;
    justify-content: flex-end;
    padding: ${({ theme }) => `0 ${theme.spacing.xxs}`};
  }
`;

export const ButtonWrapper = styled.div`
  &:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacing.xxxs};

    @media ${breakpoints.lg} {
      margin-bottom: unset;
      margin-right: ${({ theme }) => theme.spacing.xxxs};
    }
  }
`;

import { IBaseLogger, ILogger, AcceptableTags, ErrorLevels } from './types';

export class ErrorLogger implements IBaseLogger {
  constructor(
    private readonly sentryAdapter: ILogger,
    private readonly elasticAdapter: Omit<ILogger, 'setUser'>,
  ) {}

  init(): void {
    this.sentryAdapter.init();
    this.elasticAdapter.init();
  }

  setUser(userEmail: string | null): void {
    this.sentryAdapter.setUser(userEmail);
  }

  fatal(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevels.FATAL,
      error,
      additionalTags,
      additionalInfo,
    );
    this.sentryAdapter.captureError(
      ErrorLevels.FATAL,
      error,
      additionalTags,
      additionalInfo,
    );
  }

  error(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevels.ERROR,
      error,
      additionalTags,
      additionalInfo,
    );
    this.sentryAdapter.captureError(
      ErrorLevels.ERROR,
      error,
      additionalTags,
      additionalInfo,
    );
  }

  warning(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevels.WARNING,
      error,
      additionalTags,
      additionalInfo,
    );
  }

  info(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevels.INFO,
      error,
      additionalTags,
      additionalInfo,
    );
  }

  debug(
    error: string | Error,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    this.elasticAdapter.captureError(
      ErrorLevels.DEBUG,
      error,
      additionalTags,
      additionalInfo,
    );
  }
}

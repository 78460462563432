import * as BaseElasticApm from '@elastic/apm-rum';

import { ILogger, ErrorLevels, AcceptableTags } from '../types';

export class ElasticAdapter implements Omit<ILogger, 'setUser'> {
  constructor(private readonly logger = BaseElasticApm) {}

  init(): void {
    this.logger.init({
      serviceName: 'eumilitar-front',
      serverUrl: import.meta.env.VITE_ELASTIC_APM_SERVER_URL,
      environment: import.meta.env.VITE_ELASTIC_APM_ENVIRONMENT,
    });
  }

  captureError(
    errorLevel: ErrorLevels,
    error: Error | string,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    if (additionalInfo) {
      this.logger.apm.addLabels({
        ...additionalInfo,
        ...additionalTags,
        errorLevel,
      });
    }

    this.logger.apm.captureError(error);
  }
}

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRecoilValue, userDataState } from 'src/store';

import { Modal, useModal } from '../Modal';

export const RedirectToFirstAcessModal = () => {
  const userData = useRecoilValue(userDataState);
  const { isOpen, setIsOpen } = useModal();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const SHOWN_PATHS = ['/profile', '/cursos', '/curso'];

  const showRedirectToFirstAccessModal =
    SHOWN_PATHS.some((path) => pathname.includes(path)) &&
    (userData?.restriction?.includes('document') ||
      userData?.restriction?.includes('address'));

  useEffect(() => {
    const isUpdated = sessionStorage.getItem('em_userDataUpdate') === 'done';
    if (showRedirectToFirstAccessModal && !isUpdated) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [showRedirectToFirstAccessModal]);

  return (
    <Modal
      isOpen={isOpen}
      title='O Eu Militar te dá boas vindas!'
      description='Antes de continuar, complete seu cadastro com alguns dados obrigatórios.'
      primaryButtonText='Atualizar'
      primaryButtonOnClick={() => {
        navigate('/primeiro-acesso/endereco');
      }}
    />
  );
};

import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { doesUserHaveAnAuthToken } from 'src/utils/auth';

interface RedirectIfAuthedRouteProps {
  children: React.ReactNode;
  redirectTo: string;
}

const RedirectIfAuthedRoute = ({
  children,
  redirectTo,
}: RedirectIfAuthedRouteProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (doesUserHaveAnAuthToken()) {
      navigate(redirectTo);
    }
  }, [navigate, redirectTo, pathname]);

  return <>{children}</>;
};

export { RedirectIfAuthedRoute };

import { ErrorLogger } from './error-logger';
import { ElasticAdapter } from './impl/elastic-adapter';
import { SentryAdapter } from './impl/sentry-adapter';

class ErrorLoggerSingleton {
  private static instance: ErrorLogger;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): ErrorLogger {
    if (!ErrorLoggerSingleton.instance) {
      ErrorLoggerSingleton.instance = new ErrorLogger(
        new SentryAdapter(),
        new ElasticAdapter(),
      );
    }

    return ErrorLoggerSingleton.instance;
  }
}

export const Logger = ErrorLoggerSingleton.getInstance();

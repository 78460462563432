import { lazy } from 'react';

import { Route } from 'src/types/Route';

const ProfileLayout = lazy(() => import('../layouts/ProfileLayout'));
const Home = lazy(() => import('../pages/Home'));

export const profileRoutes: Route[] = [
  {
    path: '/profile',
    Component: Home,
    Layout: ProfileLayout,
    isPrivate: true,
  },
];

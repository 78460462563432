import { lazy } from 'react';

import { Route } from 'src/types/Route';

const CoursesLayout = lazy(() => import('../layouts/CoursesLayout'));
const Courses = lazy(() => import('../pages/Courses'));
const Course = lazy(() => import('../pages/Course'));
const Class = lazy(() => import('../pages/Class'));

export const coursesRoutes: Route[] = [
  {
    path: '/cursos',
    Component: Courses,
    Layout: CoursesLayout,
    isPrivate: true,
  },
  {
    path: '/curso/:slug',
    Component: Course,
    Layout: CoursesLayout,
    isPrivate: true,
  },
  {
    path: '/curso/:slug/modulo/:moduleId',
    Component: Class,
    Layout: CoursesLayout,
    isPrivate: true,
  },
];

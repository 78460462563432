import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { refetchCourses, useSetRecoilState } from 'src/store';
import { deleteUserAuthToken, saveUserAuthToken } from 'src/utils/auth';

import { auth, onAuthStateChanged } from './onAuthStateChange';

const USER_EMAIL_URL_PARAM_KEY = 'em';

const useOnAuthStateChanged = () => {
  const user = auth.currentUser;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [forceRefreshToken, setForceRefreshToken] = useState<string | null>(null);

  const setRefetchCourses = useSetRecoilState(refetchCourses);

  /**
   * emToken é o userEmail, vindo da URL. Caso esse parametro seja passado, e o
   * valor seja igual ao email do usuário autenticado, devemos dar um refresh
   * no token do usuário (fazemos isso passando true para o método getIdToken do
   * firebase).
   */
  const emToken = searchParams.get(USER_EMAIL_URL_PARAM_KEY);

  useEffect(() => {
    if (emToken) {
      setForceRefreshToken(emToken);
    }
  }, [emToken]);

  useEffect(() => {
    if (forceRefreshToken && user) {
      (async () => {
        const decodedToken = atob(forceRefreshToken);

        if (user?.email === decodedToken) {
          const userToken = await user?.getIdToken(true);
          saveUserAuthToken(userToken);
          setRefetchCourses(true);
          return;
        }
      })();
    }

    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        deleteUserAuthToken();
        return;
      }

      const userToken = await user.getIdToken();
      saveUserAuthToken(userToken);
    });
  }, [emToken, forceRefreshToken, pathname, setRefetchCourses, user]);
};

export { useOnAuthStateChanged };

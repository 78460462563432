import { lazy } from 'react';

import { authRoutes } from 'src/modules/Auth/routes';
import { coursesRoutes } from 'src/modules/Courses/routes';
import { profileRoutes } from 'src/modules/Profile/routes';
import { Route } from 'src/types/Route';

const Home = lazy(() => import('../pages/Home'));

export const appRoutes: Route[] = [
  ...coursesRoutes,
  ...authRoutes,
  ...profileRoutes,
  {
    path: '/',
    Component: Home,
    isPrivate: false,
  },
];

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';

import { ErrorBoundary } from 'src/components';
import { RecoilRoot } from 'src/store';

import { Logger } from './adapters';
import { AppRouter } from './components/AppRouter';
import { Toast } from './components/Toast';
import { QueryClientProvider, queryClient } from './services/config/react-query';
import DefaultStyles from './styles/defaut';

import 'react-toastify/dist/ReactToastify.css';

Logger.init();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <DefaultStyles />
      <ErrorBoundary>
        <HelmetProvider>
          <RecoilRoot>
            <AppRouter />
          </RecoilRoot>
          <Toast />
        </HelmetProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;

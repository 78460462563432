export enum event {
  CLICK_PLATFORM = 'click_platform',
  FILE_DOWNLOAD = 'file_download',
  VIRTUAL_PAGE_VIEW = 'virtualPageview',
  LOGIN = 'login',
  FORGOT_PASSWORD_FORM_SUBMIT = 'forgot_password_form_submit',
  FIRST_PASSWORD_FORM_SUBMIT = 'first_password_form_submit',
  FIRST_LOGIN = 'first_login',
  NEW_PASSWORD_FORM_SUBMIT = 'new_password_form_submit',
  NEW_PASSWORD_FORM_TOKEN_EXPIRED = 'new_password_form_token_expired',
}

export enum clickid {
  CLICK_MODULE = 'click_module',
  CLICK_TOP_BANNER = 'click_top_banner',
  CLICK_BANNER = 'click_banner',
  CLICK_ESSAY_ACCESS = 'click_essay_access',
  CLICK_MODULE_TRAIL = 'click_module_trail',
  CLICK_CLASS_TRAIL = 'click_class_trail',
  CLICK_CLASS_NEXT_CLASS = 'click_class_next_class',
  CLICK_NEW_COURSE = 'click_new_course',
}

export enum loginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

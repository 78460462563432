import '@wisereducacao-npm/design-system-brands-web/dist/components/provider';
import { ProviderProps } from '@wisereducacao-npm/design-system-brands-web/dist/components/provider/types';

const Provider = ({ mode, brand, children }: ProviderProps) => {
  return (
    <dsb-provider mode={mode} brand={brand}>
      {children}
    </dsb-provider>
  );
};

export { Provider };

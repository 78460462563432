import { ElasticPerformanceAdapter } from './impl/elastic-performance-adapter';
import { PerformanceWatcher as BasePerformanceWatcher } from './performance-watcher';

class PerformanceWatcherSingleton {
  private static instance: BasePerformanceWatcher;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): BasePerformanceWatcher {
    if (!PerformanceWatcherSingleton.instance) {
      PerformanceWatcherSingleton.instance = new BasePerformanceWatcher(
        new ElasticPerformanceAdapter(),
      );
    }

    return PerformanceWatcherSingleton.instance;
  }
}

export const PerformanceWatcher = PerformanceWatcherSingleton.getInstance();

import { ToastContainer, ToastContentProps } from 'react-toastify';
import {
  IconIconAttribute,
  IconColorAttribute,
} from '@wisereducacao-npm/design-system-core-web/dist/components/icon/types';
import { Icon } from '../DS/Icon';
import { Typography } from '../DS/Typography';
import { ToastType } from './types';
import { Content, TextGroup, Wrapper } from './styles';

type DataProps = {
  title: string;
  description?: string;
};

export const ToastComponent = ({
  toastProps,
  data,
}: ToastContentProps<DataProps>) => {
  const toastInfosByType: {
    [key in ToastType]: {
      icon: IconIconAttribute;
      color: IconColorAttribute;
    };
  } = {
    success: {
      icon: 'check_circle',
      color: 'feedback-success-pure',
    },
    error: {
      icon: 'warning',
      color: 'feedback-error-pure',
    },
    warning: {
      icon: 'warning_circle',
      color: 'feedback-alert-pure',
    },
  };

  return (
    <Content type={toastProps?.type as ToastType}>
      <Icon
        icon={toastInfosByType[toastProps?.type as ToastType]?.icon}
        color={toastInfosByType[toastProps?.type as ToastType]?.color}
      />
      <TextGroup>
        <Typography component="paragraph" size="small-bold">
          {data?.title}
        </Typography>
        <Typography component="paragraph" size="small">
          {data?.description}
        </Typography>
      </TextGroup>
      <Icon icon="x" color="neutral-pure" />
    </Content>
  );
};

export const Toast = () => {
  return (
    <Wrapper>
      <ToastContainer className="toast-container" />
    </Wrapper>
  );
};

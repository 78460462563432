import styled from 'styled-components';

interface PropsAvatarWrapper {
  size: string;
  background: string;
}

export const AvatarWrapper = styled.div<PropsAvatarWrapper>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  background-color: ${({ background }) => background};
  cursor: default;
`;

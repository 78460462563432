import { useQuery } from '@tanstack/react-query';

import { getUserData } from './getUserData';
import { UseGetClassProps } from './types';

export const useGetUserData = (params: UseGetClassProps) => {
  const { onSuccess, onError, enabled, cacheTime, staleTime } = params;

  return useQuery(['getUserData'], async () => await getUserData(), {
    ...(cacheTime !== undefined && { cacheTime }),
    ...(staleTime !== undefined && { staleTime }),
    ...(enabled !== undefined && { enabled }),
    ...(onError && { onError }),
    ...(onSuccess && { onSuccess }),
    refetchOnWindowFocus: false,
  });
};

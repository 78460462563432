import { atom } from 'recoil';

import { WatchedClasses, FinishedClasses } from './types';

export const watchedClassesState = atom<WatchedClasses>({
  key: 'watchedClasses',
  default: new Set<string>(),
});

export const finishedClassesState = atom<FinishedClasses>({
  key: 'finishedClasses',
  default: new Set<string>(),
});

export const refetchCourses = atom<boolean>({
  key: 'refetchCourses',
  default: false,
});

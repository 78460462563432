/* c8 ignore start */
import styled, { css } from 'styled-components';

import { convertHexToRGBA } from 'src/utils';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${convertHexToRGBA(
      theme.color.primary.pure,
      theme.opacity.semiopaque
    )};
    z-index: 1;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: ${theme.color.secondary.pure};
    }
  `}
`;
/* c8 ignore stop */

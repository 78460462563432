import { Routes, Route } from 'react-router-dom';

import { useFetchUserData } from 'src/hooks/useFetchUserData';
import { useOnAuthStateChanged } from 'src/modules/Auth/services/onAuthStateChange';
import NotFound from 'src/pages/NotFound';
import { appRoutes } from 'src/routes';

import { componentWithAuthFactory } from './utils';

const RoutesAssembler = () => {
  useOnAuthStateChanged();
  useFetchUserData();

  return (
    <Routes>
      {appRoutes.map(
        ({ path, Component, Layout, isPrivate, redirectToIfAuthenticated }) => {
          const ElementWithLayout = Layout ? (
            <Layout>
              <Component />
            </Layout>
          ) : (
            <Component />
          );

          const ComponentWithAuth = componentWithAuthFactory(ElementWithLayout, {
            isPrivate,
            redirectToIfAuthenticated,
          });

          return <Route key={path} path={path} element={ComponentWithAuth} />;
        },
      )}
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export { RoutesAssembler };

import { useState } from 'react';

function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return {
    isOpen,
    toggle,
    setIsOpen,
  };
}

export { useModal };

/* c8 ignore start */
import styled, {
  css,
  DefaultTheme,
  FlattenSimpleInterpolation,
} from 'styled-components';

import { convertHexToRGBA } from '../../utils';
import { SkeletonProps } from './types';

export const Wrapper = styled.div<SkeletonProps>`
  ${({
    theme,
    mode = 'dark',
    variant = 'text',
    width,
    height,
    isRounded = true,
    isFullHeight = false,
    mt = 0,
    mr = 0,
    mb = 0,
    ml = 0,
    br = 4,
  }) => css`
    width: 100%;
    margin: ${mt}px ${mr}px ${mb}px ${ml}px;
    border-radius: ${br}px;
    background: ${mode === 'dark'
      ? `linear-gradient(
      to right,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.medium)} 0%,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.intense)} 20%,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.medium)} 40%,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.medium)} 100%
    )`
      : `linear-gradient(
      to right,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.semiopaque)} 0%,
      ${convertHexToRGBA(theme.color.primary.light, theme.opacity.medium)} 20%,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.semiopaque)} 40%,
      ${convertHexToRGBA(theme.color.primary.medium, theme.opacity.semiopaque)} 100%
    )`};
    background-size: 80rem 14rem;
    animation: placeholderShimmer 1.3s linear infinite forwards;

    ${!!modifiers[variant] &&
    modifiers[variant](theme, { width, height, isRounded, br })}

    ${isFullHeight && modifiers.isFullHeight(theme, {})}

    @keyframes placeholderShimmer {
      0% {
        background-position: -40rem 0;
      }

      100% {
        background-position: 40rem 0;
      }
    }
  `}
`;

const modifiers: {
  [key: string]: (
    theme: DefaultTheme,
    props: SkeletonProps
  ) => FlattenSimpleInterpolation;
} = {
  text: (theme, { width = 100, height = 16 }) => css`
    max-width: ${width}px;
    height: ${height}px;
  `,
  circle: (theme, { width = 32, height = 32 }) => css`
    max-width: ${width}px;
    height: ${height}px;
    border-radius: 100%;
  `,
  rectangular: (theme, { width, height, isRounded, br }) => css`
    max-width: ${width ? `${width}px` : ''};
    height: ${height ? `${height}px` : ''};
    border-radius: ${isRounded ? br : 0}px;
  `,
  isFullHeight: () => css`
    height: 100%;
  `,
};
/* c8 ignore stop */

import { ProtectedRoute, RedirectIfAuthedRoute } from '../index';

export function componentWithAuthFactory(
  Component: JSX.Element,
  options: {
    isPrivate?: boolean;
    redirectToIfAuthenticated?: string;
  },
): JSX.Element {
  if (options.isPrivate) {
    return <ProtectedRoute>{Component}</ProtectedRoute>;
  }

  if (options.redirectToIfAuthenticated) {
    return (
      <RedirectIfAuthedRoute redirectTo={options.redirectToIfAuthenticated}>
        {Component}
      </RedirectIfAuthedRoute>
    );
  }

  return <>{Component}</>;
}

import React from 'react';

import GenericError from 'src/pages/GenericError';

import { handleUIError } from './utils';

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

interface GetDerivedStateFromErrorProps {
  hasError: boolean;
  message: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error: GetDerivedStateFromErrorProps) {
    return { hasError: true, errorMessage: error?.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    handleUIError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <GenericError errorMessage={this.state?.errorMessage} />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };

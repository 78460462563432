import { apm as elasticApm } from '@elastic/apm-rum';
import { withTransaction } from '@elastic/apm-rum-react';

import { IPerformance, AcceptableTags } from '../types';

export class ElasticPerformanceAdapter implements IPerformance {
  constructor(private readonly apm = elasticApm) {}

  instrumentComponent(
    component: React.ComponentType<unknown>,
    transactionName: string,
    transactionType: string,
  ): React.ComponentType<unknown> {
    return withTransaction(transactionName, transactionType)(component);
  }

  withMonitoring = async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    action: Function,
    eventName: string,
    eventType?: string | 'custom',
    labels?: Record<string, AcceptableTags>,
  ) => {
    if (!this.apm.isActive()) return;

    const transaction = this.apm.startTransaction(eventName, eventType, {
      managed: true,
    });

    if (!transaction) return;

    const span = transaction.startSpan(eventName, 'custom', { blocking: true });
    if (labels) transaction.addLabels(labels);
    try {
      await action(transaction);
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      if (!span) return;
      setTimeout(() => {
        span.end();
      }, 1000);
    }
  };
}

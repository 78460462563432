import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { doesUserHaveAnAuthToken } from 'src/utils/auth';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!doesUserHaveAnAuthToken()) {
      navigate('/login');
    }
  }, [navigate, pathname]);

  return <>{children}</>;
};

export { ProtectedRoute };

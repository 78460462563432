import { ErrorInfo } from 'react';

import { Logger } from '../../adapters';

export const handleUIError = (error: Error, errorInfo: ErrorInfo) => {
  const { componentStack } = errorInfo || {};

  const additionalInfo = {
    componentStack,
    Origin: 'ErrorBoundary',
    Name: error.name,
    ErrorMessage: error.message,
    OriginalException: error,
  };

  const additionalTags = {
    Origin: 'ErrorBoundary',
    Name: error.name,
  };

  Logger.fatal(error, additionalTags, additionalInfo);
};

import { ReactComponent as LoadingSvg } from '../../assets/loading.svg';
import * as S from './styles';

export const Loading = () => {
  return (
    <S.Wrapper>
      <LoadingSvg />
    </S.Wrapper>
  );
};

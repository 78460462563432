// import { useCallback, useEffect } from 'react';
import {
  useLocation,
  // useNavigate
} from 'react-router-dom';

import {
  // GetUserDataResponse,
  useGetUserData,
} from 'src/services/getUserData';
import { useRecoilState, userDataState as userDataAtomState } from 'src/store';

const DENIED_PATHS = [
  '/login',
  '/esqueci-senha',
  '/nova-senha',
  '/primeiro-acesso/senha',
];

const FIFTEEN_SECONDS_IN_MILLISECONDS = 1000 * 15;

const useFetchUserData = () => {
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  const [_, setUserDataState] = useRecoilState(userDataAtomState);

  /**
   * Se o usuário não tem esses dados, é indicativo de que não terminou de preencher
   * o formulário de primeiro acesso. Devemos garantir que ele o preencha, antes
   * de deixá-lo navegar na plataforma.
   */
  // const ensureUserFillHisPersonalData = useCallback(
  //   (userData: GetUserDataResponse) => {
  //     if (
  //       !userData.birthDate ||
  //       !userData.address?.street ||
  //       !userData.address?.district ||
  //       !userData.address?.number
  //     ) {
  //       navigate('/primeiro-acesso/endereco');
  //     }
  //   },
  //   [navigate],
  // );

  // const { data: userData, isLoading } =
  useGetUserData({
    enabled: !DENIED_PATHS.includes(pathname),
    onSuccess: (res) => {
      setUserDataState(res);
      // ensureUserFillHisPersonalData(res);
    },
    staleTime: FIFTEEN_SECONDS_IN_MILLISECONDS,
  });

  // useEffect(() => {
  //   /**
  //    * Se esses valores existirem, o user atualizou recentemente os dados
  //    */
  //   if (
  //     userDataState.birthDate &&
  //     userDataState.address?.street &&
  //     userDataState.address?.district &&
  //     userDataState.address?.number
  //   ) {
  //     return;
  //   }

  //   if (!userData || isLoading) {
  //     return;
  //   }

  //   ensureUserFillHisPersonalData(userData);
  // }, [
  //   ensureUserFillHisPersonalData,
  //   isLoading,
  //   pathname,
  //   userData,
  //   userDataState.address?.district,
  //   userDataState.address?.number,
  //   userDataState.address?.street,
  //   userDataState.birthDate,
  // ]);
};

export { useFetchUserData };

import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components';

type DefaultStylesProps = Record<string, unknown>;

const DefaultStyles: GlobalStyleComponent<
  DefaultStylesProps,
  DefaultTheme
> = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
  font-family: ${({ theme }) => theme.font.family};
  background-color: ${({ theme }) => theme.color.primary.pure};
}
`;

export default DefaultStyles;

import * as Sentry from '@sentry/react';

import { version } from '../../../../package.json';
import { ILogger, ErrorLevels, AcceptableTags } from '../types';

export class SentryAdapter implements ILogger {
  constructor(private readonly logger = Sentry) {}

  init(): void {
    this.logger.init({
      dsn: import.meta.env.VITE_SENTRY_DNS,
      normalizeDepth: 5,
      environment: import.meta.env.VITE_SENTRY_ENV,
      release: version,
      enableTracing: false,
      tracesSampleRate: 0,
      beforeSend(event) {
        /**
         * O codigo abaixo visa evitar que erros sejam lancados
         * em duplicidade (pois o Sentry lanca automaticamente
         * alguns erros, e ao lancarmos manualmente tambem, para
         * podermos ter uma maior granularidade, pode gerar erros
         * em duplicidade no Sentry).
         */
        const isFromReact =
          Array.isArray(event.extra?.arguments) &&
          event.extra?.arguments[0]?.target === 'react';

        if (!isFromReact && !event.extra?.isFromHandler) return null;

        return event;
      },
    });
  }

  setUser(userEmail: string | null): void {
    const userIdentifier = userEmail ? { email: userEmail } : null;
    this.logger.setUser(userIdentifier);
  }

  captureError(
    errorLevel: ErrorLevels,
    error: Error | string,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ): void {
    this.logger.withScope((scope) => {
      if (additionalInfo) {
        if (
          additionalInfo.requestUrl &&
          additionalInfo.statusCode &&
          additionalInfo.requestMethod
        ) {
          const formattedRequestUrl = String(additionalInfo.requestUrl).split('?');
          const requestUrlWithoutQueryStr = formattedRequestUrl[0];
          const queryString = formattedRequestUrl[1];

          if (requestUrlWithoutQueryStr) {
            scope.setFingerprint([
              requestUrlWithoutQueryStr,
              String(additionalInfo.requestMethod),
              String(additionalInfo.statusCode),
            ]);
          }
          if (queryString) {
            scope.setExtra('queryString', queryString);
          }
        }
        scope.setContext('Additional Info', additionalInfo);
      }

      if (additionalTags) scope.setTags(additionalTags);

      scope.setExtra('errorLevel', errorLevel);
      scope.setTag('errorLevel', errorLevel);
      scope.setLevel(errorLevel);

      scope.setExtra('isFromHandler', true);

      this.logger.captureException(error);
    });
  }
}

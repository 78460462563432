import { AxiosError } from 'axios';

import { Logger } from 'src/adapters/';
import { AcceptableTags } from 'src/adapters/Logger/types';

const captureErrorFromLevel = (statusCode: number | undefined) => {
  return (
    error: AxiosError,
    additionalTags: Record<string, AcceptableTags>,
    additionalInfo: Record<string, unknown>,
  ) => {
    if (!statusCode) {
      return Logger.warning(error, additionalTags, additionalInfo);
    }

    if (statusCode < 400) {
      return Logger.info(error, additionalTags, additionalInfo);
    }

    if (statusCode >= 500) {
      return Logger.error(error, additionalTags, additionalInfo);
    }

    return Logger.warning(error, additionalTags, additionalInfo);
  };
};

export const handleApiError = (error: AxiosError) => {
  const additionalInfo = {
    errorMessage: error.cause?.message || error.message || 'No error message',
    statusCode: error.response?.status.toString() || 'No status code',
    requestMethod: error.config?.method || 'No request method',
    requestUrl: error.config?.url || 'No request url',
    requestResponse: error.response?.data || 'No request response',
    genericErrorMessage: JSON.stringify(error.message) || 'No error message',
    originalException: error,
  };

  const additionalTags = {
    requestMethod: error.config?.method || 'No request method',
    statusCode: error.response?.status.toString() || 'No status code',
    requestUrl: error.config?.url || 'No request url',
  };

  captureErrorFromLevel(error.response?.status)(
    error,
    additionalTags,
    additionalInfo,
  );
};

import { lazy } from 'react';

import { Route } from 'src/types/Route';

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const NewPassword = lazy(() => import('../pages/NewPassword'));
const FirstAccessEndereco = lazy(() => import('../pages/FirstAccessEndereco'));
const FirstAccessPassword = lazy(() => import('../pages/FirstAccessPassword'));

export const authRoutes: Route[] = [
  {
    path: '/login',
    Component: Login,
    isPrivate: false,
    redirectToIfAuthenticated: '/cursos',
  },
  {
    path: '/esqueci-senha',
    Component: ForgotPassword,
    isPrivate: false,
    redirectToIfAuthenticated: '/cursos',
  },
  {
    path: '/nova-senha',
    Component: NewPassword,
    isPrivate: false,
  },
  {
    path: '/primeiro-acesso/senha',
    Component: FirstAccessPassword,
    isPrivate: false,
  },
  {
    path: '/primeiro-acesso/endereco',
    Component: FirstAccessEndereco,
    isPrivate: true,
  },
];

import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import error404 from 'src/assets/error-404.svg';
import { Logo, Typography } from 'src/components';
import * as dataLayer from 'src/utils/dataLayer';

import * as S from './styles';

const NotFound = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    dataLayer.push({
      event: dataLayer.event.VIRTUAL_PAGE_VIEW,
      page_url: pathname,
    });
  }, [pathname]);

  return (
    <S.Wrapper>
      <Logo />
      <S.Content>
        <S.ImageBox>
          <img src={error404} alt='error 404' />
        </S.ImageBox>
        <S.TextBox>
          <Typography component='heading' size='x-large' color='neutral-medium'>
            Erro 404
          </Typography>
          <S.Title component='heading' size='medium'>
            Não conseguimos encontrar esta página
          </S.Title>
          <S.Subtitle component='paragraph' size='large' color='neutral-high'>
            A página que você procura não existe ou foi removida. Acesse a{' '}
            <Link to='/'>página inicial</Link> ou a nossa{' '}
            <Link to='#'>central de ajuda</Link>.
          </S.Subtitle>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  );
};

export { NotFound };

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { Provider } from './components/DS/Provider';
import { theme } from './themes';

/**
 *
 * MSW mock server
 * Comment this to disable the mock server
 *
 */
//import('./test/mocks/browser').then(({ worker }) => {
//  worker.start();
//});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider brand='em' mode='dark'>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

import { createComponent } from '@lit-labs/react';
import {
  DsbTypography,
  TypographyProps,
} from '@wisereducacao-npm/design-system-brands-web/dist/components/typography';
import * as React from 'react';

interface DsTypographyProps extends TypographyProps {
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

export const Typography = createComponent({
  tagName: 'dsb-typography',
  elementClass: DsbTypography,
  react: React,
  events: {},
}) as unknown as React.FC<DsTypographyProps>;

import { atom } from 'recoil';

import { User } from './types';

export const userDataState = atom<User>({
  key: 'userData',
  default: {
    id: null,
    fullName: null,
    birthDate: null,
    phone: null,
    email: null,
    address: null,
    restriction: null,
  },
});

import axios from 'axios';

import { handleApiError } from 'src/services/utils';
import { getUserAuthToken } from 'src/utils/auth';

import { version } from '../../../../package.json';

type Config = {
  headers?: Record<string, string>;
};

export function baseApiFactory(BASE_URL?: string, config?: Config) {
  if (!BASE_URL) {
    throw new Error('BASE_URL env var missing');
  }

  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      ...(config?.headers && config?.headers),
    },
    timeout: 30000,
    validateStatus: (status) => status >= 200 && status < 300,
  });

  api.interceptors.request.use(
    (config) => {
      const jwtToken = getUserAuthToken();

      if (config?.headers) {
        config.headers = {
          ...config.headers,
          appVersion: version,
          ...(!config?.headers['Authorization'] &&
            jwtToken && { Authorization: `Bearer ${jwtToken}` }),
        };
      }

      return config;
    },
    (error) => {
      handleApiError(error);
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (res) => res,
    (err) => {
      handleApiError(err);
      return Promise.reject(err);
    },
  );

  return api;
}

import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Loading } from '../Loading';
import { RedirectToFirstAcessModal } from '../RedirectToFirstAcessModal';
import { RoutesAssembler } from './components';

const AppRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <RoutesAssembler />
        <RedirectToFirstAcessModal />
      </Router>
    </Suspense>
  );
};

export { AppRouter };

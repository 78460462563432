export enum ErrorLevels {
  FATAL = 'fatal',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEBUG = 'debug',
}

export type AcceptableTags = number | string | boolean;

type captureExceptionFunction = (
  error: string | Error,
  additionalTags?: Record<string, AcceptableTags>,
  additionalInfo?: Record<string, unknown>,
) => void;

export type IBaseLogger = {
  init: () => void;
  setUser: (userEmail: string | null) => void;
  fatal: captureExceptionFunction;
  error: captureExceptionFunction;
  warning: captureExceptionFunction;
  info: captureExceptionFunction;
  debug: captureExceptionFunction;
};

export type ILogger = {
  init: () => void;
  setUser: (userEmail: string | null) => void;
  captureError: (
    errorLevel: ErrorLevels,
    error: Error | string,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>,
  ) => void;
};

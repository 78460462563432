import * as jsBrandTokens from '@wisereducacao-npm/design-system-tokens/dist/js/em/dark/dark';

const theme = {
  color: {
    primary: {
      dark: jsBrandTokens?.ColorPrimaryDark,
      light: jsBrandTokens?.ColorPrimaryLight,
      medium: jsBrandTokens?.ColorPrimaryMedium,
      pure: jsBrandTokens?.ColorPrimaryPure,
    },
    secondary: {
      dark: jsBrandTokens?.ColorSecondaryDark,
      light: jsBrandTokens?.ColorSecondaryLight,
      medium: jsBrandTokens?.ColorSecondaryMedium,
      pure: jsBrandTokens?.ColorSecondaryPure,
    },
    neutral: {
      button: jsBrandTokens?.NeutralColorButton,
      highContrast: jsBrandTokens?.NeutralColorHighContrast,
      lowContrast: jsBrandTokens?.NeutralColorLowContrast,
      mediumContrast: jsBrandTokens?.NeutralColorMediumContrast,
      pure: jsBrandTokens?.NeutralColorPure,
    },
    feedback: {
      success: {
        highContrast: jsBrandTokens?.FeedbackColorSuccessHighContrast,
        lowContrast: jsBrandTokens?.FeedbackColorSuccessLowContrast,
        mediumContrast: jsBrandTokens?.FeedbackColorSuccessMediumContrast,
        pure: jsBrandTokens?.FeedbackColorSuccessPure,
      },
      alert: {
        highContrast: jsBrandTokens?.FeedbackColorAlertHighContrast,
        lowContrast: jsBrandTokens?.FeedbackColorAlertLowContrast,
        mediumContrast: jsBrandTokens?.FeedbackColorAlertMediumContrast,
        pure: jsBrandTokens?.FeedbackColorAlertPure,
      },
      error: {
        highContrast: jsBrandTokens?.FeedbackColorErrorHighContrast,
        lowContrast: jsBrandTokens?.FeedbackColorErrorLowContrast,
        mediumContrast: jsBrandTokens?.FeedbackColorErrorMediumContrast,
        pure: jsBrandTokens?.FeedbackColorErrorPure,
      },
      button: jsBrandTokens?.FeedbackColorButton,
    },
  },
  spacing: {
    giant: 'var(--spacing-giant)',
    huge: 'var(--spacing-giant)',
    insetLg: 'var(--spacing-inset-lg)',
    insetMd: 'var(--spacing-inset-md)',
    insetNano: 'var(--spacing-nano)',
    insetQuarck: 'var(--spacing-inset-quarck)',
    insetSm: 'var(--spacing-inset-sm)',
    insetXs: 'var(--spacing-inset-xs)',
    insetXxs: 'var(--spacing-inset-xxs)',
    lg: 'var(--spacing-lg)',
    md: 'var(--spacing-md)',
    nano: 'var(--spacing-nano)',
    quarck: 'var(--spacing-quarck)',
    sm: 'var(--spacing-sm)',
    xl: 'var(--spacing-xl)',
    xs: 'var(--spacing-xs)',
    xxl: 'var(--spacing-xxl)',
    xxs: 'var(--spacing-xxs)',
    xxxl: 'var(--spacing-xxxl)',
    xxxs: 'var(--spacing-xxxs)',
    xxxxs: 'var(--spacing-xxxxs)',
  },
  font: {
    size: {
      display: 'var(--font-size-display)',
      giant: 'var(--font-size-giant)',
      lg: 'var(--font-size-lg)',
      md: 'var(--font-size-md)',
      sm: 'var(--font-size-sm)',
      xl: 'var--font-size-xl)',
      xs: 'var(--font-size-xs)',
      xxl: 'var(--font-size-xxl)',
      xxs: 'var(--font-size-xxs)',
      xxxl: 'var(--font-size-xxxl)',
      xxxs: 'var(--font-size-xxxs)',
    },
    weight: {
      bold: 'var(--font-weight-bold)',
      light: 'var(--font-weight-light)',
      regular: 'var(--font-weight-regular)',
      semiBold: 'var(--font-weight-semi-bold)',
    },
    family: {
      primary: 'var(--font-family-primary)',
    },
  },
  lineHeight: {
    default: 'var(--line-height-default)',
    lg: 'var(--line-height-lg)',
    md: 'var(--line-height-md)',
    sm: 'var(--line-height-sm)',
    xl: 'var(--line-height-xl)',
    xs: 'var(--line-height-xs)',
    xxl: 'var(--line-height-xxl)',
  },

  border: {
    radius: {
      circular: 'var(--border-radius-circular)',
      lg: 'var(--border-radius-lg)',
      md: 'var(--border-radius-md)',
      none: 'var(--border-radius-none)',
      pill: 'var(--border-radius-pill)',
      sm: 'var(--border-radius-sm)',
      xs: 'var(--border-radius-xs)',
    },
    width: {
      hairline: 'var(--border-width-hairline)',
      heavy: 'var(--border-width-heavy)',
      none: 'var(--border-width-none)',
      thick: 'var(--border-width-thick)',
      thin: 'var(--border-width-thin)',
    },
  },
  opacity: {
    intense: 'var(--opacity-level-intense)',
    light: 'var(--opacity-level-light)',
    medium: 'var(--opacity-level-medium)',
    regular: 'var(--opacity-level-regular)',
    semiopaque: 'var(--opacity-level-semiopaque)',
    semitransparent: 'var(--opacity-level-semitransparent)',
  },
  shadow: {
    level1: 'var(--shadow-level1)',
    level2: 'var(--shadow-level2)',
    level3: 'var(--shadow-level3)',
    level4: 'var(--shadow-level4)',
  },

  width: {
    maxWidth: '1320px',
  },
  breakpoints: {
    xxs: '375px',
    xs: '425px',
    sm: '600px',
    md: '768px',
    lg: '1024px',
    xl: '1200px',
    xxl: '1400px',
  },
};

export { theme };

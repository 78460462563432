import { Link } from 'react-router-dom';

import { ReactComponent as LogoImg } from '../../assets/logo.svg';
import * as S from './Logo.styles';

export const Logo = () => {
  return (
    <Link to='/'>
      <S.LogoWrapper>
        <LogoImg />
      </S.LogoWrapper>
    </Link>
  );
};

/* c8 ignore start */
import styled, { css } from 'styled-components';

import { breakpoints, convertHexToRGBA } from 'src/utils';

export const Wrapper = styled.div`
  border-bottom: ${({ theme }) =>
    `${theme.border.width.hairline} solid ${convertHexToRGBA(
      theme.color.primary.light,
      theme.opacity.medium,
    )}`};
`;

export const TopNavigation = styled.div`
  padding: 0 4px;
  min-height: ${({ theme }) => theme.spacing.md};
  display: flex;
  align-items: center;
  max-width: ${({ theme }) => theme.width.maxWidth};
  width: 100%;
  margin: 0 auto;

  @media ${breakpoints.lg} {
    padding: 0 20px;
  }
`;

interface BackButtonWrapperProps {
  backTo?: string;
}

export const BackButtonWrapper = styled.div<BackButtonWrapperProps>`
  ${({ backTo }) => css`
    flex: 1;
    display: flex;
    align-items: center;
    opacity: ${backTo ? 1 : 0};
    pointer-events: ${backTo ? 'all' : 'none'};
  `}
`;

export const Balancer = styled.div`
  flex: 1;
`;

export const BackButtonText = styled.div`
  display: none;

  @media ${breakpoints.md} {
    display: inline;
  }
`;

export const SkeletonWrapper = styled.div`
  width: 160px;
`;
/* c8 ignore stop */

import { useEffect } from 'react';

import { Button } from 'src/components/DS/Button';
import { Icon } from 'src/components/DS/Icon';
import { Typography } from 'src/components/DS/Typography';

import * as S from './styles';

interface ModalProps {
  isOpen: boolean;
  title: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  onClose?: () => void;
  primaryButtonOnClick: () => void;
  secondaryButtonOnClick?: () => void;
}

const Modal = ({
  isOpen,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  primaryButtonOnClick,
  secondaryButtonOnClick,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <S.OverFlowWrapper isOpen={isOpen} onClick={onClose}>
      <S.ModalContainer
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
        data-testid='logout-modal'>
        <S.ModalHeader>
          {onClose && (
            <Button onClick={onClose} component='tertiary'>
              <Icon icon='x' />
            </Button>
          )}
        </S.ModalHeader>

        <S.ModalBody>
          <S.TitleContainer>
            <Typography component='paragraph' size='large-bold'>
              {title}
            </Typography>
          </S.TitleContainer>

          <Typography component='caption'>{description}</Typography>
        </S.ModalBody>

        <S.ModalFooter>
          <S.ButtonWrapper>
            <Button component='primary' onClick={primaryButtonOnClick}>
              {primaryButtonText}
            </Button>
          </S.ButtonWrapper>

          {secondaryButtonText && (
            <S.ButtonWrapper>
              <Button
                data-testid='modal-logout-button'
                iconLeft='sign_out'
                component='secondary'
                onClick={secondaryButtonOnClick}>
                {secondaryButtonText}
              </Button>
            </S.ButtonWrapper>
          )}
        </S.ModalFooter>
      </S.ModalContainer>
    </S.OverFlowWrapper>
  );
};

export { Modal };
